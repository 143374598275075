import "./App.css";
import { Navbar, Hero, Products, Leadership, Footer } from "./components/index";

function App() {
  return (
    <div className="App">
      <div className="App-container">
        <Navbar />
        <Hero />
        <Products />
        <Leadership />
        <Footer />
      </div>
    </div>
  );
}

export default App;
