import React from "react";
import "./leadership.css";

function index() {
  return (
    <div className="Leadership">
      <div className="Leadership-container">
        <div className="Leadership-header">Leadership</div>
        <div className="Leadership-leaders">
          <div className="Leader">
            <div className="Leader-image">
              <img
                src="https://ik.imagekit.io/trakka/Compressed_Jpegs/timi-1?ik-sdk-version=javascript-1.4.3&updatedAt=1652614338436"
                alt=""
              />
            </div>
            <div className="Leader-text">
              <div className="Leader-info">Oluwatimilehin Ogunme - CEO</div>
            </div>
          </div>
          <div className="Leader">
            <div className="Leader-image">
              <img
                src="https://ik.imagekit.io/trakka/Compressed_Jpegs/rasheed-2?ik-sdk-version=javascript-1.4.3&updatedAt=1652702663513"
                alt=""
              />
            </div>
            <div className="Leader-text">
              <div className="Leader-info">Rasheed Aro - COO</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
