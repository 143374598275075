import React from "react";
import "./navbar.css";
import logo from "../assets/finstaq-logo.svg";

function index() {
  return (
    <div className="Navbar">
      <div className="Navbar-container">
        <div className="finstaq-logo">
          <img src={logo} alt="" />
        </div>
      </div>
    </div>
  );
}

export default index;
