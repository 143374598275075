import React from "react";
import "./footer.css";

function index() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  return (
    <div className="Footer">
      <div className="Footer-container">
        {" "}
        <p>{getCurrentYear()} © Finstaq Technologies</p>
      </div>
    </div>
  );
}

export default index;
