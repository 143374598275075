import React from "react";
import "./products.css";
import Trakka from "../assets/Trakka-logo.svg";
import send from "../assets/send.svg";

function index() {
  return (
    <div className="Products">
      <div className="Products-container">
        <div className="Products-header">Our Products</div>
        <div className="Products-Trakka">
          <img src={Trakka} alt="" />
        </div>
        <div className="Products-Trakka-info">
          Trakka is the all-in-one personal finance tool that helps you
          centralize all your bank accounts, track how you spend, tells you what
          you spend on, and gives you the best budgeting system to manage your
          money all in one beautiful app. Trakka gives you all you need to stay
          on top of your finances and maintain a heavy pocket.
        </div>
        <div className="Products-Trakka-visit">
          <a href="https://www.trakka.me/" target="_blank">
            Visit Trakka <img src={send} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default index;
