import React from "react";
import "./hero.css";
import heroImage from "../assets/hero-image.svg";

function index() {
  return (
    <div className="Hero">
      <div className="Hero-container">
        <div className="Hero-text">
          <div className="Hero-main-text">
            Building financial tools that matter
          </div>
          <div className="Hero-subText">
            Our vision is to build an ecosystem of financial products for
            consumers and businesses alike. 
          </div>
        </div>
        <div className="Hero-image">
          <img src={heroImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default index;
